<template>
    <div class="tabbed-page">
        <tabbed-page-item
            v-for="(item, index) of tabList"
            :key="index"
            :data="item"
            v-hasPermi="item.vHasPermi"
        >
        </tabbed-page-item>
    </div>
</template>
<script>
import TabbedPageItem from './TabbedPageItem.vue';
export default {
    name: "TabbedPage",
    components: {
        TabbedPageItem
    },
    props: {
        tabList: Array
    }
}
</script>
<style lang="scss" scoped>
    .tabbed-page {
        position: relative;
        margin-top: 6px;
        display: flex;
    }
</style>
