<template>
    <div class="table-data" :style="{ 'border-radius': borderRadius }">
        <el-table
            ref="multipleTable"
            class="gk-table"
            :key="tableKey"
            :class="{ 'table_dense': density === '2' }"
            :row-key="config.rowkey"
            :row-style="rowStyle"
            :data="tableData"
            :height="table_config.height || null"
            :border="table_config.border || false"
            :indent="table_config.indent"
            :header-cell-style="{background:backgroundHeader,color:headerColor}"
            :cell-style="{color:headerColor}"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" label-width="auto"
            :row-class-name="tableRowClassName"
            :default-expand-all="table_config.isExpandAll || false"
            stripe
            @filter-change="handleFilterChange"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            @cell-click="handleCellClick"
            @expand-change="handleExpandChange"
            @sort-change="handleSortChange">
            <!-- 选择框 -->
            <el-table-column
                v-if="table_config.check"
                type="selection"
                :selectable="table_config.checkSelectAble"
                width="50"
                align="center"
            ></el-table-column>

            <!-- 序号 -->
            <el-table-column
                v-if="table_config.showIndex"
                class-name="index-column text-spacing-reduction"
                type="index"
                label="序号"
                align="center"
                :fixed="table_config.indexFixed"
                :width="table_config.showIndexWidth || '80px'"
            >
            </el-table-column>

            <!-- 左边距占位 -->
            <el-table-column
                v-if="!table_config.check && !table_config.showIndex && table_config.leftEmpty !== false"
                class-name="left-empty"
                type="index"
                label=""
                :fixed="table_config.thead && table_config.thead[0] && table_config.thead[0].fixed ? table_config.thead[0].fixed : false"
                align="center"
                :width="table_config.leftEmptyWidth || '26px'">
            </el-table-column>

            <template v-for="(item) in this.table_config.thead">
                <!-- switch ↓ -->
                <el-table-column
                    v-if="item.type === 'switch' && (item.special === '' || typeof item.special === 'undefined')"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :key="item.id"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row[item.prop]"
                            :active-value="item.hasOwnProperty('activeValue') ? item.activeValue : '1'"
                            :inactive-value="item.hasOwnProperty('inactiveValue') ? item.inactiveValue : '0'"
                            :disabled="item.disabled"
                            @change="switchHandler(scope.row, item)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- link ↓ -->
                <el-table-column
                    v-else-if="item.type === 'link'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :key="item.id"
                    :fixed="item.fixed"
                    :align="item.align || 'left'"
                    :show-overflow-tooltip="item.tooltip === false ? false : true"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    {{ item.fixed }}
                    <template slot-scope="scope">
                        <template v-if="!['schoolName', 'teacherName', 'studentName', 'name', 'templateName'].includes(item.prop)">
                            <div v-for="(subItem, subIndex) in scope.row[item.prop]" :key="subIndex">
                                <el-button :type="item.btnType || 'text'" @click="linkClick(scope.row, subItem, subIndex)">{{ subItem }}</el-button>
                            </div>
                        </template>
                        <template v-else>
                            <el-button
                                v-if="determinePermission(item.clickAuth)"
                                class="text-ellipsis"
                                :style="{ 'text-align': item.align || 'left' }"
                                :type="item.btnType || 'text'"
                                :disabled="item.disabled ? item.disabled(scope.row, item.prop) : false"
                                @click="linkClick(scope.row, item)"
                            >{{ scope.row[item.prop] }}</el-button>
                            <span v-else style="white-space: nowrap;">{{ scope.row[item.prop] }}</span>
                        </template>
                    </template>
                </el-table-column>
                <!-- smj ↓ -->
                <el-table-column
                    v-else-if="item.type === 'smj'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row"></slot>
                    </template>
                </el-table-column>
                <!-- progress ↓ -->
                <el-table-column
                    v-else-if="item.type === 'progress'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <span v-html="item.callBack && item.callBack(scope.row, item.prop)"></span>
                        <el-progress type="circle" :percentage="scope.row.openingRate" :color="scope.row.status === '0' ? '#BFBFBF' : item.color" :width="item.width" :stroke-width="item.strokeWidth" ></el-progress>
                    </template>
                </el-table-column>
                <!-- popover ↓ -->
                <el-table-column
                    v-else-if="item.type === 'popover'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <el-popover v-if="scope.row[item.prop] && scope.row[item.prop].split(',').length > 0" placement="top">
                            <div class="popover-wrapper">
                                <el-tag
                                    type="success"
                                    v-for="(item, index) in scope.row[item.prop].split(',').filter(i => i !== '')"
                                    :key="index"
                                >{{ item }}</el-tag>
                            </div>
                            <el-tag v-if="item.notShowMore" type="success" slot="reference">{{ scope.row[item.prop].split(',').length > 1 ?  scope.row[item.prop].split(',')[0]+'...' : scope.row[item.prop].split(',')[0]}}</el-tag>
                            <el-tag v-else type="success" slot="reference">{{ scope.row[item.prop].split(',')[0] }}...</el-tag>
                        </el-popover>
                    </template>
                </el-table-column>
                <!-- popoverText ↓ 文字过长时，使用... ，鼠标移上去显示文字 -->
                <el-table-column
                    v-else-if="item.type === 'popoverText'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth ? item.minWidth : (item.labelWidth ? '' : '100px')"
                    :show-overflow-tooltip="true"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop]" class="text-ellipsis">{{ scope.row[item.prop] }}</div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
                <!-- images ↓ -->
                <el-table-column
                    v-else-if="item.type === 'images'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :fixed="item.fixed"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <div :style="`width: 100%;text-align: ${item.align || 'center'};line-height:100%;`">
                            <template v-if="scope.row[item.prop] || (!scope.row[item.prop] && !item.imgErrorAdr)">
                                <el-image
                                    :src="scope.row[item.prop]"
                                    :style="{
                                        'width': item.width || '40px',
                                        'height': item.height || '40px',
                                        'border-radius': item.borderRadius || '50%',
                                        'display': 'inline-block'
                                    }"
                                    :preview-src-list="item.showPreview ? [scope.row[item.prop]] : []">
                                    <div slot="error" class="image-slot" style="height: 100%;background-color: #e7ebf5;"></div>
                                </el-image>
                            </template>
                            <el-image
                                v-else
                                :src="item.imgErrorAdr"
                                :style="{
                                    'width': item.width || '40px',
                                    'height': item.height || '40px',
                                    'border-radius': item.borderRadius || '50%',
                                    'display': 'inline-block'
                                }"
                            >
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <!-- dropdown ↓ -->
                <el-table-column
                    v-else-if="item.type === 'dropdown'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <el-dropdown :disabled="item.disabled" trigger="click">
                            <span
                                :style="(item.style && item.style[scope.row[item.prop]]) ? item.style[scope.row[item.prop]] : {}"
                                class="el-dropdown-link">
                                <span
                                    v-if="item.list.find(i => i.value == scope.row[item.prop])"
                                    class="dropdown-title"
                                >{{ item.list.find(i => i.value == scope.row[item.prop]).label }}</span>
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    style="font-size: 14px;"
                                    v-for="(item, index) in item.list"
                                    :key="index"
                                    @click.native="dropDownClick(scope.row, item)"
                                >{{ item.label }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <!-- Invalid ↓ -->
                <el-table-column
                    v-else-if="item.type === 'Invalid'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :sortable="item.sortable ? item.sortable : false"
                    :fixed="item.fixed"
                    :width="item.labelWidth"
                    :min-width="item.minWidth ? item.minWidth : (item.labelWidth ? '' : '100px')"
                    :show-overflow-tooltip="true"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <span :class="{red:item.callBack(scope.row, item.prop)=='已失效'||item.callBack(scope.row, item.prop)=='失效'}" v-if="item.callBack && item.callBack(scope.row, item.prop)" v-html="item.callBack && item.callBack(scope.row, item.prop)" class="text-ellipsis"></span>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
                <!-- function ↓ -->
                <el-table-column
                    v-else-if="item.type === 'function'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :sortable="item.sortable ? item.sortable : false"
                    :fixed="item.fixed"
                    :width="item.labelWidth"
                    :min-width="item.minWidth ? item.minWidth : (item.labelWidth ? '' : '100px')"
                    :show-overflow-tooltip="true"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <template v-if="!['studentName'].includes(item.prop)">
                            <span v-if="item.callBack && item.callBack(scope.row, item.prop)" v-html="item.callBack && item.callBack(scope.row, item.prop)" class="text-ellipsis"></span>
                            <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                            <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                        </template>
                        <template v-else>
                            <el-button
                                v-if="determinePermission(item.clickAuth)"
                                :type="item.btnType || 'text'"
                                :disabled="item.disabled ? item.disabled(scope.row, item.prop) : false"
                                @click="linkClick(scope.row, item)"
                            >{{ scope.row[item.prop] }}</el-button>
                            <span v-else style="white-space: nowrap;">{{ scope.row[item.prop] }}</span>
                        </template>
                    </template>
                </el-table-column>
                <!-- multilayer ↓ -->
                <el-table-column
                    v-else-if="item.type === 'multilayer'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :show-overflow-tooltip="true"
                    :key="item.id"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div
                            class="list"
                            v-for="(subItem, index) in scope.row[item.prop]"
                            :key="index"
                        >{{ subItem }}</div>
                    </template>
                </el-table-column>
                <!-- switch ↓ -->
                <el-table-column
                    v-else-if="item.type == 'switch' && item.special == 'multilayer'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :key="item.id"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <div
                            v-for="(subItem, subIndex) of scope.row[item.prop]"
                            :key="subIndex">
                            <el-switch
                                :key="subIndex"
                                v-model="scope.row[item.prop][subIndex]"
                                active-value="1"
                                inactive-value="0"
                                @change="switchHandlerMultilayer(scope.row, subIndex)"
                            ></el-switch>
                        </div>
                    </template>
                </el-table-column>
                <!-- slot ↓ -->
                <el-table-column
                    v-else-if="item.type === 'slot'"
                    :class-name="item.className || ''"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :show-overflow-tooltip="item.tooltip === true ? true : false"
                    :align="item.align || 'center'"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'center'" />
                    </template>
                    <template slot-scope="scope">
                        <table-operation :key="+new Date()" v-if="item.slotName === 'operation'" :item="item" :scope="scope">
                            <slot :name="item.slotName" :data="scope.row" :item="item"></slot>
                        </table-operation>
                        <slot v-else :name="item.slotName" :data="scope.row" :index="scope.$index" :item="item"></slot>
                    </template>
                </el-table-column>
                <!-- slotAndIcon ↓ -->
                <el-table-column
                    v-else-if="item.type === 'slotAndIcon'"
                    :class-name="item.className || ''"
                    :render-header="item.renderHeader"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :show-overflow-tooltip="item.tooltip === true ? true : false"
                    :fixed="typeof item.fixed !== 'undefined' ? item.fixed : 'right'"
                    :align="item.align || 'left'">
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <table-operation :key="+new Date()" v-if="item.slotName === 'operation'" :item="item" :scope="scope">
                            <slot :name="item.slotName" :data="scope.row" :item="item"></slot>
                        </table-operation>
                        <slot v-else :name="item.slotName" :data="scope.row" :item="item"></slot>
                    </template>
                </el-table-column>
                <!-- slot1 ↓ -->
                <el-table-column
                    v-else-if="item.type === 'slot1'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    fixed="left"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <table-operation :key="+new Date()" v-if="item.slotName === 'operation'" :item="item" :scope="scope">
                            <slot :name="item.slotName" :data="scope.row" :item="item"></slot>
                        </table-operation>
                        <slot v-else :name="item.slotName" :data="scope.row"></slot>
                    </template>
                </el-table-column>
                <!-- desensitize ↓ -->
                <el-table-column
                    v-else-if="item.type === 'desensitize'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :show-overflow-tooltip="true"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop]">
                            <span :style="scope.row[item.prop].indexOf('*') === -1 ? {color: '#3672d9'} : {}">{{scope.row[item.prop]}}</span>
                            <img
                                v-has-permi="item.auth"
                                class="desensitize-icon"
                                :src="scope.row[item.prop].indexOf('*') === -1 ? imgDesensitizeShow : imgDesensitizeHide"
                                @click="item.callBack(scope.row)"
                                alt=""
                            >
                        </div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
                <!-- tooltipThead ↓ -->
                <el-table-column
                    v-else-if="item.type === 'tooltipThead'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'left'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="true"
                    :class-name="item.className"
                    :minWidth="item.minWidth">
                    <template slot="header">
                        <text-tooltip :content="item.label" :placement="item.placement || 'right-end'"></text-tooltip>
                    </template>
                </el-table-column>
                <!-- setHeader ↓ -->
                <el-table-column
                    v-else-if="item.type === 'setHeader'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'left'"
                    :fixed="typeof item.fixed !== 'undefined' ? item.fixed : 'right'"
                    :show-overflow-tooltip="true"
                    :class-name="item.className"
                    :minWidth="item.minWidth">
                    <template slot="header" slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row"></slot>
                    </template>
                    <template slot-scope="scope">
                        <slot :name="item.slotName1" :data="scope.row"></slot>
                    </template>
                </el-table-column>
                <!-- customization ↓ -->
                <el-table-column
                    v-else-if="item.type === 'customization'"
                    :key="item.id"
                    label-class-name="table_header"
                    :align="item.align || 'left'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <i
                            style="cursor: pointer;"
                            :class="item.class"
                            @click="customization"
                        ></i>
                    </template>
                </el-table-column>
                <!-- tooltipColumn ↓ -->
                <el-table-column
                    v-else-if="item.type === 'tooltipColumn'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'left'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="false"
                    :filters="item.filters"
                    :column-key="item.columnKey"
                    :filtered-value="item.filterValue"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop]" class="tooltip-container">
                            <div v-if="!scope.row[`${item.prop}Truncated`]" class="tooltip-prop">{{ getTooltipShowAndDisplayText(scope.row, item) }}</div>
                            <el-tooltip
                                v-else
                                effect="dark"
                                placement="right-start"
                                :offset="-6"
                                popper-class="table-tooltip-column-popper">
                                <div class="tooltip-content" slot="content">
                                    <div v-for="(item, index) in getTooltipContentArr(scope.row, item)" :key="index">
                                        <span>{{ item }}</span>
                                    </div>
                                </div>
                                <div class="tooltip-prop">{{ getTooltipShowAndDisplayText(scope.row, item) }}</div>
                            </el-tooltip>
                        </div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
                <!-- tooltipColumnSpecial ↓ -->
                <el-table-column
                    v-else-if="item.type === 'tooltipColumnSpecial'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'left'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="false"
                    :filters="item.filters"
                    :column-key="item.columnKey"
                    :filtered-value="item.filterValue"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop]" class="tooltip-container">
                            
                            <el-tooltip
                                effect="dark"
                                placement="right-start"
                                :offset="-6"
                                popper-class="table-tooltip-column-popper">
                                <div class="tooltip-content" slot="content">
                                    <div v-for="(item, index) in getTooltipContentArr(scope.row, item)" :key="index">
                                        <span>{{ item }}</span>
                                    </div>
                                </div>
                                <div class="tooltip-special-prop">{{ getSpecialText(scope.row[item.prop],item)  }}</div>
                                <!-- <i class="el-icon-more tooltip-icon"></i> -->
                            </el-tooltip>
                        </div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
                <!-- setting ↓ -->
                <el-table-column
                    v-else-if="item.type === 'setting'"
                    :key="item.id"
                    width="45"
                    :align="item.align || 'center'"
                    :class-name="item.className || ''"
                >
                    <template slot="header" slot-scope="scope">
                        <i class="el-icon-s-tools table-setting" @click="handlerSettingClick"></i>
                    </template>
                </el-table-column>
                <!-- others ↓ -->
                <el-table-column
                    v-else
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :min-width="item.minWidth ? item.minWidth : (item.labelWidth ? '' : '100px')"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'left'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="true"
                    :filters="item.filters"
                    :column-key="item.columnKey"
                    :filtered-value="item.filterValue"
                    :formatter="item.formatter"
                    :class-name="item.className || ''"
                    >
                    <template slot="header" slot-scope="scope">
                        <table-header :item="item" :align="item.align || 'left'" />
                    </template>
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0" class="text-ellipsis">{{ scope.row[item.prop] }}</div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label" style="{text-align: center;width: 100%;}">-</div>
                    </template>
                </el-table-column>
            </template>
        </el-table>
    </div>
</template>

<script>
import TableHeader from "./TableData/TableHeaderLine"
import TextTooltip from "./TableData/TextTooltip";
import TableOperation from "./TableData/TableOperation"

export default {
    name: "TableData",
    components: {
        TableHeader,
        TextTooltip,
        TableOperation
    },
    props: {
        config: {
            type: Object,
            default: () => {
            }
        },
        tableData: {
            type: Array,
            default: () => []
        },
        borderRadius: {
            type: String,
            default: '0px 0px 0 0'
        },
        backgroundHeader: {
            type: String,
            default: '#fafbfc'
        },
        headerColor: {
            type: String,
            default: '#595959'
        },
        rows: {
            type: Array
        },
        toTop: {
            type: Boolean,
            default: true
        },
        density: {
            type: String,
            default: '1'
        },
        rowStyle: {
            type: Object,
            default: () => {
                return {
                    height: '48px'
                }
            }
        }
    },
    data() {
        return {
            /*
            *	thead 表头
            *	check 复选框
            *
            */
            table_config: {
                thead: [],
                leftEmpty: true,
                leftEmptyWidth: '26px',
                check: true,
                checkSelectAble: function() {
                    return true
                },
                showIndex: false,
                indexFixed: false,
                rowkey: '',
                isExpandAll: '',
                border: false,
                height: '',
                showIndexWidth: '',
                isCloseRow: {
                    key: '',
                    value: ''
                },
                indent: 16,
                rowClassNameFn: false
            },
            haschild: true,
            // bjColorc: ['#ff5857', '#3385ff'],
            tableKey: Math.random(),
        }
    },
    filters: {},
    computed: {
        imgDesensitizeShow() {
            return require('@/assets/images/desensitize-show.png');
        },
        imgDesensitizeHide() {
            return require('@/assets/images/desensitize-hide.png');
        },
    },
    mounted() {},
    methods: {
        init() {
            for (let key in this.config) {
                if (Object.keys(this.table_config).includes(key)) {
                    this.table_config[key] = this.config[key];
                }
            }
        },
        // 判断权限
        determinePermission(permissionFlag) {
            if (!permissionFlag || (permissionFlag && permissionFlag.length == 0)) {
                return true
            }
            const all_permission = '*:*';
            const permissions = this.$store.state.permissions;
            const hasPermissions = permissions.some(permission => {
                return all_permission === permission || permissionFlag.includes(permission)
            })
            if (hasPermissions) {
                return true
            }
            return false
        },
        getTooltipShowAndDisplayText(row, header) {
            let value = row[header.prop]
            if (!header.labelWidth) {
                return value
            }
            if (!value || (value && value.length == 0)) {
                this.$set(row, `${header.prop}Truncated`, false)
                return ''
            }

            let text = ''
            if (Array.isArray(value)) {
                if (header.displayKey) {
                    text = value[0][header.displayKey]
                } else {
                    text = value[0]
                }
            } else if (typeof value === 'string') {
                if (header.splitSign) {
                    value = value.split(header.splitSign)
                } else {
                    value = [value]
                }
                text = value[0]
            } else {
                return value
            }

            let truncated = false
            if (value.length > 1) {
                truncated = true
            }

            let labelWidth = parseInt(header.labelWidth.replace('px', '')) - 20
            const pattern = /[\u4e00-\u9fa5\u3000-\u303f\uff01-\uff3a\uff3f-\uff5a]/; // 匹配中文和中文符号的正则表达式
            const fontSize = 14
            let width = 0
            // 如果truncated不为true，判断text是否会截断
            if (truncated !== true) {
                for (let i in text) {
                    if (pattern.test(text[i])) {
                        width += fontSize
                    } else {
                        width += Math.ceil(fontSize * 0.6)
                    }
                }
                if (width <= labelWidth) {
                    this.$set(row, `${header.prop}Truncated`, truncated)
                    return text
                } else {
                    truncated = true
                    width = 0
                }
            }

            if (truncated === true) {
                labelWidth -= 20
            }
            let displayText = ''
            for (let i in text) {
                // console.log(text[i],  pattern.test(text[i]))
                if (pattern.test(text[i])) {
                    width += fontSize
                } else {
                    width += Math.ceil(fontSize * 0.6)
                }
                if (width <= labelWidth) {
                    displayText += text[i]
                } else {
                    truncated = true
                    break
                }
            }
            // console.log("[getTooltipShowAndDisplayText]displayText:", displayText, "width:", width, "truncated:", truncated)
            this.$set(row, `${header.prop}Truncated`, truncated)
            return displayText
        },
        getTooltipContentArr(row, header) {
            let value = row[header.prop]
            if (Array.isArray(value)) {
                if (header.displayKey) {
                    return value.map(e => e[header.displayKey])
                } else {
                    return value
                }
            } else if (typeof value === 'string') {
                if (header.splitSign) {
                    return value.split(header.splitSign)
                } else {
                    return [value]
                }
            }
        },
        handleRowClick(ev) {
            this.$emit('handleRowClick', ev)
        },
        handleCellClick(ev,column, cell, event){
            this.$emit('handleCellClick', ev, column, cell, event)
        },
        handleSortChange(ev, prop, order){
            this.$emit('handleSortChange', ev, prop, order)
        },
        handleFilterChange(ev){
            this.$emit('handleFilterChange',ev)
        },
        handleSelectionChange(ev) {
            this.$emit('handleSelectionChange', ev)
        },
        switchHandler(ev, item) {
            this.$emit('switchHandler', ev, item)
        },
        switchHandlerMultilayer (ev, idx) {
            console.log('ch')
            this.$emit('switchHandlerMultilayer', ev, idx)
        },
        linkClick(ev, item, index) {
            this.$emit('linkClick', ev, item, index)
        },
        dropDownClick(ev, data) {
            this.$emit('dropDownClick', ev, data)
        },
        toggleRowSelection () {
            if (this.rows) {
                this.rows.forEach((row) => {
                    this.$nextTick(() => {
                        this.$refs.multipleTable.toggleRowSelection(this.tableData[`${row}`])
                    })
                })
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        customization () {
            this.$emit('customization')
        },
        handlerSettingClick() {
            this.$emit('tableSetting')
        },
        getSpecialText(text,header){
            let str = text;

            if(header.signReplace){
                console.log(header);
                str = str.replace(new RegExp(header.splitSign,"g"),header.signReplace);
            }

            return str;
        },
        /**
         * @Description: 展开收起监听
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-03 14:06:41
         */
         handleExpandChange(row, expandedRows) {
            row.isOpen = expandedRows;
        },
         /**
         * @Description: 动态添加class
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 15:25:20
         * @param {*} data
         */
         tableRowClassName(data) {
            let { row } = data;
            if (row.level == 1) {
                let o = row.isOpen ? "one-level-row" : "one-level-row close";
                let t = row.paddingClass ? "paddingLeft" : "";
                let rowClass = row.rowClass ? row.rowClass : "";
                return o + " " + t + " " + rowClass;
            }
            
            if (row.level == 2) {
                let o = "";
                let en = "";
                let t = row.isLast
                    ? "second-level-row last"
                    : "second-level-row";

                if (row.isLast) {
                    o = row.isOpen ? "" : "close";
                }

                if (row.isEnd) {
                    en = "close";
                }

                let rowClass = row.rowClass ? row.rowClass : "";
                return o + " " + t + " " + rowClass + " " + en;
            }
            if (row.level > 2) {
                let o = "";
                let t = "second-level-row";
                let rowClass = row.rowClass ? row.rowClass : "";

                if (row.isEnd) {
                    o = "close";
                }

                return o + " " + t + " " + rowClass;
            }
        },
    },
    watch: {
        config: {
            handler(newValue) {
                this.init();
            },
            immediate: true,
            deep: true
        },
        tableData: {
            handler () {
                if (this.toTop) {
                    this.$nextTick(() => {
                        this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                    })
                }
                
                this.$nextTick(() => {
                    this.$forceUpdate();
                })
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.el-tooltip__popper.is-dark {
    /* background: rgba(247, 247, 248, 0.9)!important;
    color: #000; */
    max-width: 260px;
    line-height: 24px;
}

.table-tooltip-column-popper {
    .tooltip-content {
        max-height: 60vh;
        overflow-y: auto;
    }
    .tooltip-content::-webkit-scrollbar {
        display: none;
    }
}

.el-table__header-wrapper .text-spacing-reduction {
    span{
        // display:block !important;
        letter-spacing:14px !important;
        // margin-right:-14px !important;
        // padding-right:20px;
    }
    div{
        // display:block !important;
        letter-spacing:14px !important;
        margin-right:-5px !important;
        // padding-right: 0px !important;
    }
}
.el-table__header .text-spacing-reduction {
    span{
        // display:block !important;
        letter-spacing:14px !important;

        // margin-right:-14px !important;
        // padding-right:20px;
    }
        div{
        // display:block !important;
        letter-spacing:14px !important;
        // margin-right:-14px !important;
        // padding-right: 0px !important;
    }
}
</style>

<style lang="scss" scoped>
.table-data {
    box-sizing: border-box;
    background-color: #fff;
    padding-bottom: 10px;

    ::v-deep .el-table {
        .left-empty .cell {
            display: none;
        }

        th.el-table__cell.is-sortable > .cell {
            padding-right: 24px;
        }

        .caret-wrapper {
            position: absolute;
            top: -5px;
        }

        .el-table__row {
            height: 56px;
        }

        td.el-table__cell, th.el-table__cell.is-leaf {
            border: unset;
        }

        .el-table__fixed-right::before,
        .el-table__fixed::before {
            background-color: transparent;
        }

        .close-row .cell {
            color: #BFBFBF !important;
        }
        .close-row .cell .el-dropdown-link {
            color: #BFBFBF !important;
        }
        .childSty-row {
            background-color: #F7F9FA !important;
        }

        /* 鼠标悬浮行 */
        .el-table__body .el-table__row.hover-row td {
            background-color: #EBEFF5 !important;
        }

        .el-button--text {
            padding: 0;
        }

        .text-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        .red{
            color: rgb(240, 12, 12);
        }
    }
}

.popover-wrapper {
    max-width: 310px;
    .el-tag {
        margin: 5px;
        text-align: center;
    }
}

.el-dropdown-link {
    cursor: pointer;
    font-size: 14px;
}

.desensitize-icon {
    cursor: pointer;
    margin-left: 5px;
}

.tooltip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .tooltip-prop {
        white-space: nowrap;
        overflow: hidden;
        /* text-overflow: ellipsis; */
        /* display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; */
    }

    .tooltip-special-prop {
        white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
    }

    .tooltip-icon {
        width: 14px;
        height: 14px;
        background: #AAAEB3;
        border-radius: 50%;
        text-align: center;
        line-height: 14px;
        font-size: 10px;
        color: #fff;
        cursor: pointer;
        flex-shrink: 0;
        margin-left: 6px;
    }
    .tooltip-icon:hover {
        background: #177ee6;
    }
}

.table-setting {
    font-size: 15px;
    color: #B9B9B9;
    cursor: pointer;
}

::v-deep .table_dense.el-table {
    .el-table__row {
        height: 44px;
    }

    .el-table__body-wrapper,
    .el-table__fixed,
    .el-table__fixed-right {
        .el-table__row .el-table__cell {
            padding-top: 0;
            padding-bottom: 0;

            .cell {
                line-height: 1;
            }
        }
    }
}

::v-deep .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    // padding-right: 100%;
}
</style>
