<template>
    <div
        :class="['tabbed-page-item', { current: data.sel }]"
        @click="changeTabs"
    >
        {{ data.title }}
    </div>
</template>
<script>
export default {
    name: "TabbedPageItem",
    props: {
        data: {
            type: Object
        }
    },
    methods: {
        changeTabs () {
            this.$eventDispatch('changeTabs', this.data)
        }
    }
}
</script>
<style lang="scss" scoped>
    .tabbed-page-item {
        height: 40px;
        line-height: 40px;
        margin-right: 5px;
        box-sizing: border-box;
        padding: 0 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        background-color: #F8F8F8;
        border-radius: 6px 6px 0px 0px;
        cursor: pointer;
    }
    .tabbed-page-item:hover {
        background-color:#DDE9FF;
        color: #2B2F33;
    }
    .tabbed-page-item.current {
        background-color: #FFFFFF;
        color: #3C7FFF;
        font-weight: 600;
    }
</style>
